import React from "react"
import { Link } from "gatsby"
import Layout from "../../../components/layout"
import SEO from "../../../components/seo"
import business_architecture from '../../../images/pages/business_architecture.jpg'
import title_image from '../../../images/titles/business_architecture.jpg'
import person from '../../../images/person.png'

const pageTitle = "Business Architecture";
const pageSLug = "business-architecture";
class ValueTargeting extends React.Component{
  render(){
    return(
      <Layout pageType="business-consulting">
      <SEO title={pageTitle} />
      <section className="section page-title" style={{ backgroundImage: `url(${title_image})`}}>
      <div className="overlay"></div>
        <div className="container">
            <h1 className="title">{pageTitle}</h1>
            <h6 className="subtitle">We develop Business Architecture that can give your organization insight into future improvements.</h6>
        </div>
      </section>

      <section id="p_business_consulting" className="section static-page page-content">
        <div className="container">
            <div className="columns">
                <div className="column is-12 content-area">
                    <h3>To accomplish business transformation objectives, you will need an effective business architecture. 
                        Having a capable business architecture plays a critical role in delivering products and services in 
                        a low cost and high-quality manner.</h3>
                    <p>Your business architecture will define the structure of technology, processes, organization structure, roles and 
                        competencies, metrics, and infrastructure, which is then customized to support new business strategies.</p>
                    <img src={business_architecture} />
                    <p>Our experts in process, strategy, and technology will work with you to provide seamless business architecture services.</p>
                    <span className="break"></span>
                   <p>Not sure where to start? Together we can figure that out.</p>
                   <Link to="/contact-us" className="button green">Contact us</Link>
                   <span className="break"></span>
                   <h4>Service Capabilities</h4>
                   <h6>Our Business Architecture services include:</h6>
                   <ul>
                       <li>Process Architecture</li>
                       <li>Technology and Enterprise Architecture</li>
                       <li>Shared Services Design and Strategy</li>
                       <li>Capability Modeling and Design</li>
                       <li>Organizational Design and Role Definition</li>
                   </ul>
                   <span className="break"></span>
                   <h4>Business Architecture Services</h4>
                   <h5>Capability Modeling and Design </h5>
                   <p>An organization's Core capabilities are vital functions that play a critical role in delivering value to the market. Together we 
                       define targeted business outcomes and design requirements for these capabilities, providing you with essential 
                       inputs to process, organization, and technology architectures.</p>
                    <h4>Process Architecture</h4>
                    <p>Together we apply deep capabilities in process integration and design, to help you develop process 
                        architectures. We also use lean and Six Sigma principles to design effective and cost-efficient processes.</p>
                    <h4>Shared Services Strategy and Design</h4>
                    <p>Consider many factors when determining which shared service services structure is most appropriate for your business. 
                        Such as what type of service you offer, the business requirements, size of the operation, unit requirements, 
                        as well as acquisition and expansion strategies. Together we work to identify the best-shared services model for 
                        you, to enhance the operational effectiveness of various functions in your organization.</p>
                    <h4>Organization Design and Role Definition companies</h4>
                    <p>We work with you to identify the most appropriate structure based on your core capability 
                        requirements and business priorities. Whether it's process-based, functional-based, consumer-based, 
                        or a product-based model. Our experts can help you define your key competencies and roles that are 
                        required to support your organization's needs.</p>
                    <h4>Technology and Enterprise Architecture</h4>
                    <p>Technology and infrastructure solutions continue to evolve, as previous solutions become obsolete, new solutions 
                        are needed to align with current business needs. Having an aligned enterprise architecture is crucial to 
                        ensuring applications meet the specific needs of your organization and integrate themselves in a seamless, 
                        flexible, and cost-effective manner. Our technology experts can help you define infrastructure and application 
                        requirements. We then work with you to define comprehensive enterprise architectures and guide future technology 
                        investments.</p>
                        <div className="contact-box">
                    <h3>Want to find out what improving your Business Architecture can do for your business?</h3>
                        <span className="break"></span>
                        <div className="button-area">
                            <Link to="/contact-us" className="button default">Find out today</Link>
                        </div>
                    </div>
                </div>
              {/*  <div className="column is-3 sidebar">
                    <div className="person-info">
                        <img src={person} />
                        <div className="inner">
                            <h2 className="name">Greg Klem</h2>
                            <p className="status">Vice President</p>
                            <p>National Practice Lead - Business </p>
                            <p>Consulting Services</p>
                        </div>
                        <p>Not sure where to start, our team is always ready to help.</p>
                        <Link to="/" className="button green">Talk with a professional</Link>
                    </div>
                </div>*/}
            </div>
        </div>
      </section>
      </Layout>
    )
  }
}

export default ValueTargeting
